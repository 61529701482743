<template>
<!-- Section 1 -->
<section class="flex flex-col w-full bg-gradient-to-l from-purple-500 to-blue-500 md:flex-row">
    <div class="flex flex-col items-center justify-center w-full p-10 md:w-1/2 lg:p-16 xl:p-24">
        <h2 class="max-w-lg text-4xl font-light leading-tight text-white md:text-3xl lg:text-4xl xl:text-5xl">We have the tools you need to succeed</h2>
        <p class="max-w-lg mt-5 text-xl text-white md:text-base lg:text-xl">Our powerful and revolutionary tools are designed to help your business thrive.</p>
    </div>

    <div class="w-full md:w-1/2">
        <img src="https://imaginicampanii.s3.us-east-2.amazonaws.com/banererra.jpeg" class="inset-0 object-cover">
    </div>
</section>
</template>

<script>
export default {
  name: 'CaseOcupate',
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.logo{
    width: 100px;
    height: 100px;
}

</style>
