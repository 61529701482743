<template>
   <video-background 
    src="https://imaginicampanii.s3.us-east-2.amazonaws.com/CASI+VIDEO.mp4"
    style="max-height: 750px; height: 100vh;"
 >
<div class="container">
	<div class="row">
		<div class="banner-text">
<h1 id="title-behind">Bun venit</h1>
<h1 id="title-the">la</h1>
<h1 id="title-lens">ErЯa</h1>
<h2 id="subtitle">Rezidence</h2>
</div>
	</div>
</div>
 </video-background>
</template>

<script>
export default {
 name: 'VideoHeader',
}
</script>

<style>
.banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 99;
}

h1,
h2 {
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 0.8;
    text-shadow: 0 0 50px #fff;
    transform: translateZ(130px);
    -webkit-transform: translateZ(130px);
    -moz-transform: translateZ(130px);
    color: hsla(0, 0%, 0%, 0);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    letter-spacing: 100px;
    opacity: 0;
    -webkit-animation: OpeningSequence 3.2s ease forwards;
    -moz-animation: OpeningSequence 3.2s ease forwards;
    animation: OpeningSequence 3.2s ease forwards;
}

h1 {
    font-size: 100px;
}

h2 {
    font-size: 18px;
}

#title-behind,
#title-the,
#title-lens,
#subtitle {
    letter-spacing: 0px;
    opacity: 1;
    transform: translateZ(20px);
    -webkit-transform: translateZ(20px);
    -moz-transform: translateZ(20px);
}

#title-behind {
    text-shadow: 0 0 10px #fff;
}

#title-the {
    text-shadow: 0 0 5px #fff;
}

#title-lens,
#subtitle {
    text-shadow: 0 0 0px #fff;
}

#subtitle {
    margin-top: 30px;
}

@-webkit-keyframes OpeningSequence {
    0% {
        letter-spacing: 50px;
        opacity: 0;
        transform: translateZ(130px);
        -webkit-transform: translateZ(130px);
        -moz-transform: translateZ(130px);
    }
    100% {
        letter-spacing: 5px;
        opacity: 1;
        transform: translateZ(20px);
        -webkit-transform: translateZ(20px);
        -moz-transform: translateZ(20px);
    }
}

@-moz-keyframes OpeningSequence {
    0% {
        letter-spacing: 50px;
        opacity: 0;
        transform: translateZ(130px);
        -webkit-transform: translateZ(130px);
        -moz-transform: translateZ(130px);
    }
    100% {
        letter-spacing: 5px;
        opacity: 1;
        transform: translateZ(20px);
        -webkit-transform: translateZ(20px);
        -moz-transform: translateZ(20px);
    }
}

@keyframes OpeningSequence {
    0% {
        letter-spacing: 50px;
        opacity: 0;
        transform: translateZ(130px);
        -webkit-transform: translateZ(130px);
        -moz-transform: translateZ(130px);
    }
    100% {
        letter-spacing: 5px;
        opacity: 1;
        transform: translateZ(20px);
        -webkit-transform: translateZ(20px);
        -moz-transform: translateZ(20px);
    }
}
</style>