<template>
<section class="pt-16 pb-20 sm:pt-24 sm:pb-32 bg-gray-50">
    <div class="items-center max-w-6xl px-12 mx-auto sm:px-20 md:px-5">
        <div class="flex flex-wrap items-center -mx-3">
            <div class="order-1 w-full px-3 lg:w-1/2 lg:order-0">
                <div class="w-full max-w-md xl:max-w-xl">
                    <h2 class="relative mb-6 text-3xl font-semibold leading-tight tracking-tight xl:font-bold lg:text-6xl xl:text-7xl text-black">
                        Beautiful designs that help you look pretty.
                    </h2>
                    <ul>
                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">Automated Workflows</span>
                        </li>
                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">Drag'n Drop Interface</span>
                        </li>
                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">Unlimited API Calls</span>
                        </li>
                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">24/7 Customer Support</span>
                        </li>
                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">99.9% Uptime</span>
                        </li>
                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">Analytics Dashboard</span>
                        </li>

                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">Fully Customizable</span>
                        </li>

                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">Seamless Integrations</span>
                        </li>

                        <li class="flex items-center py-2 space-x-4 xl:py-3">
                            <img src="https://cdn.devdojo.com/images/february2021/checkmark.png" class="w-5 h-5">
                            <span class="font-medium font-bold text-gray-700">So Much More!</span>
                        </li>


                    </ul>
                </div>
            </div>
            <div class="relative w-full px-3 mb-12 lg:-ml-20 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
                <img class="absolute top-0 right-0 z-10 hidden mx-auto -mt-32 rounded-lg shadow-2xl opacity-100 xl:-mr-12 sm:max-w-xs lg:max-w-sm lg:block" src="https://imaginicampanii.s3.us-east-2.amazonaws.com/imggalerie5.jpeg">
                <img class="relative z-20 w-full mx-auto mt-3 rounded-lg shadow-2xl sm:max-w-none lg:-ml-10 lg:max-w-sm" src="https://imaginicampanii.s3.us-east-2.amazonaws.com/imggalerie2.jpeg" alt="feature image">
                <img class="absolute bottom-0 right-0 z-10 hidden mx-auto -mb-48 rounded-lg shadow-2xl xl:-mr-12 sm:max-w-xs lg:max-w-sm lg:block" src="https://imaginicampanii.s3.us-east-2.amazonaws.com/imggalerie3.jpeg">
            </div>
        </div>
    </div>
</section>
</template>
<!-- AlpineJS Library -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/alpinejs/2.8.0/alpine.js"></script>
<script>
export default {
  name: 'Avantaje',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
